import React, { useEffect, useState } from 'react';
import getIP from './get-ip';
// import useCountryLocation from './use-country-location';

export const myContext = React.createContext();

const Provider = (props) => {
  //   const { countryInit, error } = useCountryLocation(getIP);
  const [country, setCountry] = useState('');
  useEffect(() => {
    getIP()
      .then((res) => {
        setCountry(res);
      })
      .catch((e) => setCountry('DE'));
  });

  return (
    <myContext.Provider
      value={{
        country,
        setCountry,
      }}
    >
      {props.children}
    </myContext.Provider>
  );
};

export default ({ element }) => <Provider>{element}</Provider>;
