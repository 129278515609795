import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFA100',
      white: '#FFFFFF',
      black: '#3D3838',
      green: '#00C5B7',
      purple: '#8F4FFF',
    },
  },
});

export default theme;
