const getIP = () => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(
    'https://pro.ip-api.com/json/?key=uNYA2W7wbrZBT9Z',
    // 'http://ip-api.com/json/',
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(`Request error. ${response.status}`);
    })
    .then((data) => data.countryCode)
    .catch((error) => 'DE');
};

export default getIP;
